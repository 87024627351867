<template>
  <div class="mt-4" id="mainData">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex items-end flex-wrap gap-4">
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
        <button
          class="bg-blue-500 text-white py-2 px-3 text-sm rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
          @click="getData"
        >
          <font-awesome-icon icon="fa-solid fa-search" class="mr-2" />
          Consultar
        </button>
      </div>
      <div class="flex items-center flex-wrap gap-4">
        <button
          class="bg-blue-500 text-white py-2 px-3 text-sm rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
          @click="activeModalRegister"
        >
          <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
          Nuevo servicio
        </button>
        <button
          class="bg-green-500 text-white py-2 px-3 text-sm rounded-lg flex items-center outline-none shadow-sm hover:shadow-green-500 hover:bg-green-600"
          @click="activePaymentRegister"
        >
          <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
          Nuevo Pago
        </button>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre o numero de documento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full" id="dataTable">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="11" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.usuario }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.cliente }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.servicio.slice(3, register.servicio.length) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.numero_facturado }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.total_monto_facturado) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.igv_facturado) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.igv_retenido) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.forma_de_pago.slice(
                      3,
                      register.forma_de_pago.lenght
                    )
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ formatDate(register.fecha_de_pago) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ formatDate(register.fecha_de_comprobante) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-blue-100 text-blue-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-700"
                    @click="activePaymentUpdate(register.id)"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen" class="w-3 h-3" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterNewServicesDGII
    :activeModal="activeModalRegister"
    v-if="stateRegister"
  />
  <RegisterPaymentServicesDGII
    :activeModal="activePaymentRegister"
    :getData="getData"
    v-if="statePaymentRegister"
  />
  <UpdatePaymentServicesDGII
    :activeModal="activePaymentUpdate"
    :getdata="getData"
    :id="idData"
    v-if="statePaymentUpdate"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterNewServicesDGII from "@/components/ServicesDGII/RegisterNewServicesDGII.vue";
import RegisterPaymentServicesDGII from "@/components/ServicesDGII/RegisterPaymentServicesDGII.vue";
import UpdatePaymentServicesDGII from "@/components/ServicesDGII/UpdatePaymentServicesDGII.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { formatDate } from "@/publicjs/formatDate";

import config from "/public/apis/config.json";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { idLocalStore } from "@/publicjs/localStorage";

export default {
  name: "MainServicesDGII",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterNewServicesDGII,
    RegisterPaymentServicesDGII,
    UpdatePaymentServicesDGII,
  },
  setup() {
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Usuario",
        },
        {
          title: "cliente",
        },
        {
          title: "servicio",
        },
        {
          title: "comprobante",
        },
        {
          title: "total ",
        },
        {
          title: config.iva + " facturado",
        },
        {
          title: config.iva + " retenido",
        },
        {
          title: "forma de pago",
        },
        {
          title: "fecha de pago",
        },
        {
          title: "fecha comprobante",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      dateStart: null,
      dateEnd: null,
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `pago-de-servicios/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${dataForm.value.idStore}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data.data;
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      observeElement("#mainData");
      observeElement("#dataTable");
      handleResize();
      window.addEventListener("resize", handleResize);

      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = dates.firstDay;
      dataForm.value.dateEnd = dates.lastDay;
      dataForm.value.idStore = await idLocalStore();

      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    const stateRegister = ref(false);
    const activeModalRegister = () => {
      stateRegister.value = !stateRegister.value;
    };
    const statePaymentRegister = ref(false);
    const activePaymentRegister = () => {
      statePaymentRegister.value = !statePaymentRegister.value;
    };
    const statePaymentUpdate = ref(false);
    const idData = ref(0);
    const activePaymentUpdate = (id) => {
      statePaymentUpdate.value = !statePaymentUpdate.value;
      idData.value = id;
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      widthNav,
      handleResize,
      money,
      format,
      formatDate,
      activePaymentRegister,
      statePaymentRegister,
      activePaymentUpdate,
      idData,
      statePaymentUpdate,
      dataForm
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
