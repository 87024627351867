<template>
  <div class="mt-4" id="contendData">
    <div class="flex flex-wrap items-end gap-4 mb-3">
      <label class="block w-full md:w-auto">
        <span
          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
        >
          Fec. de inicio
        </span>
        <input
          type="date"
          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
          v-model="dataForm.dateStart"
          @keydown.enter.prevent
        />
      </label>
      <label class="block w-full md:w-auto">
        <span
          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
        >
          Fec. término
        </span>
        <input
          type="date"
          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
          v-model="dataForm.dateEnd"
          @keydown.enter.prevent
        />
      </label>
      <button
        class="bg-blue-50 text-blue-500 px-3 py-2 border-2 border-blue-50 hover:border-blue-500 hover:bg-blue-500 hover:text-white text-sm rounded-md font-semibold"
        @click="getData"
      >
        Consultar
      </button>
    </div>
    <div
      class="bg-blue-50 border-2 border-blue-100 p-3 rounded-md mb-2"
      v-if="dataForm.commissionState"
    >
      <h2 class="text-blue-500 text-sm font-bold">
        Configuración de la comisión
      </h2>
      <div class="flex flex-wrap gap-4 mt-3">
        <div class="bg-blue-100 p-3 rounded-md">
          <h2 class="text-blue-500 text-xs font-bold">Monto a superar</h2>
          <div class="font-lg mt-2 font-semibold text-gray-600">
            {{ money() }} {{ format(registers.configuracion.monto) }}
          </div>
        </div>
        <div class="bg-blue-100 p-3 rounded-md">
          <h2 class="text-blue-500 text-xs font-bold">Comisión configurada</h2>
          <div class="font-lg mt-2 font-semibold text-gray-600">
            {{ registers.configuracion.comision }} %
          </div>
        </div>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
        id="tableContend"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="3" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers.usuarios"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                >
                  <button
                    class="bg-blue-600 py-2 px-2 text-xs font-semibold text-white rounded-md active:scale-95"
                    @click="appliedCommission(register.idusuario, register.total, register.nombres)"
                  >
                    Aplicar comisión
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import {
  errorActions,
  uploadData,
  warningActions,
} from "@/alerts";

import { money, format } from "@/publicjs/money";
import { formatDateTime } from "@/publicjs/formatDate";
import { idLocalStore } from "@/publicjs/localStorage";
import { observeElement } from "@/observer";
import { percentage } from "@/publicjs/convertToPercentage";
import Swal from "sweetalert2";

export default {
  name: "MainSellerOfTheMonth",
  components: {
    LoadingTables,
    TableHead,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const loading = ref(true);
    const header = [
      [
        {
          title: "usuario",
        },
        {
          title: "total",
        },
        {
          title: "acciones",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      dateEnd: null,
      dateStart: null,
      commissionState: false,
      commission: 0,
    });

    const getData = async () => {
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor ingresa el rango de fechas para porder realizar el reporte"
        );
        return;
      }
      loading.value = true;
      dataForm.value.commissionState = false;
      const response = await axios
        .get(
          `vendedor-de-mes/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      // console.log(data);

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = data;
      loading.value = false;
      dataForm.value.commissionState = true;
    };

    /**
     *
     * @param {*} idUser id del usuario al cual se la alplicara su comisión
     * @param {*} amount monto generado del usuario
     */
    const insert = async (idUser, amount) => {
      uploadData(
        "APLICANDO COMISIÓN",
        "Espera un momento mientras aplicamos la comision del usuario"
      );
      const response = await axios
        .post(`vendedor-de-mes`, {
          idUser,
          commission:
            parseFloat(amount) *
            percentage(registers.value.configuracion.comision),
        })
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.msg === false) {
        errorActions(
          "Los sentimos no pudimos registrar la comisión del usuario  😥"
        );
      }
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      observeElement("#contendData");
      observeElement("#tableContend");
      dataForm.value.idStore = await idLocalStore();
      loading.value = false;
    });

    //SweetAlert para confirmas la eliminacion del registro
    const appliedCommission = async (idUser, amount, userName) => {
      Swal.fire({
        title: "¿Estas seguro que deseas aplicar la comision?",
        html: `Si aplicas la comision al usuario <strong>${userName}</strong> se le sumará a la comisión anterior!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡Aplicar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await insert(idUser, amount);
          Swal.fire({
            icon: "success",
            title: "Aplicado",
            html: `Se a aplicado la comision al usuario <strong>${userName}</strong> `,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });

          await getData();
        }
      });
    };

    return {
      loading,
      getData,
      header,
      registers,
      widthNav,
      handleResize,
      money,
      format,
      formatDateTime,
      dataForm,
      insert,
      appliedCommission
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
