import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { errorActions, uploadData, closeAlert } from "@/alerts"; //? Alertas de error, etc
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { formatDateTime } from "@/publicjs/formatDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import { paperDimension } from "@/publicjs/paperDimension"; //? Convierte moneda a letras
import axios from "@/api";
import styles from "@/publicjs/styles";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import boxConfigurationAccordingToCountry from "/public/apis/boxConfigurationAccordingToCountry.json";

//* Función para imprimir el documento
/**
 * @param {*} idOrder | ID de la ultima orden realizada
 */
export const printTicket = async (idOrder) => {
  uploadData(
    "Generando cuadre de caja",
    "Espera un momento mientras consultamos los datos"
  );

  //Estado para mostrar o no campo
  let boxConfiguration = false;
  if (boxConfigurationAccordingToCountry.country === "PERU") {
    boxConfiguration = true;
  }
  //* Poblado de datos de la empresa
  const dataStore = JSON.parse(localStorage.getItem("access"));
  const company = await getCompany(dataStore.user.store);

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  //? Obtencion de datos de la orden
  const registers = await order(idOrder);
  try {
    //Desestructurando el ancho y al alto del papel
    const regenerateHeight = parseFloat(360);
    const { paperWidth, paperHeight } = paperDimension(78, regenerateHeight);

    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: { width: paperWidth, height: paperHeight },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait", //cambia a [landscape] para imprimir de forma horizontal

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 270, 10, 10],
      //!encabezado del documento con datos de la empresa, sucursal y datos del comprobante
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            width: "*",
            stack: [
              { image: base64Image, fit: [40, 40] }, // logo
            ],
            alignment: "center",
            margin: [10, 10, 0, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: company.companyName,
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
              },
              {
                text: "Dirección: " + company.companyAddress,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "Tel: " + company.companycellphone,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: company.companySlogan,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text:
                  "Sucursal: ".toUpperCase() +
                  registers.razon_social.toUpperCase(),
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
                margin: [0, 10, 0, 0],
              },
              {
                text: "DIR: " + registers.direccion.toUpperCase(),
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "TEL: " + registers.celular,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
            ],
            alignment: "center",
            margin: [10, 10, 10, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: titlesConfig.titleTypeDocument + company.companyRUC,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: "APERTURA:" + formatDateTime(registers.fecha_apertura),
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: registers.fecha_de_cierre
                  ? "CIERRE: " + formatDateTime(registers.fecha_de_cierre)
                  : "---",
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
            alignment: "center",
            margin: [10, 0, 10, 0],
          },
          {
            canvas: [
              { type: "rect", x: 10, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [10, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      content: [
        //? Encabezado del comprobante
        {
          table: {
            headerRows: 0,
            widths: [66, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Responsable:",
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text:
                    registers.nombres.length >= 24
                      ? registers.proveedor.razon_social
                          .slice(0, 24)
                          .toUpperCase() + "***"
                      : registers.nombres.toUpperCase(),
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [-15, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Turno:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.descripcion,
                  border: [false, false, false, false],
                  margin: [-35, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
            ],
          },
        },
        //?Titulo de cuadre de caja
        {
          text: "********************************************************".toUpperCase(),
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: [100, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Monto inicial",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_inicial),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Otros ingresos",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_en_credito),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto en cheque",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_en_cheque),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto en tarjeta",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_en_tarjeta),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto en efectivo",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_en_efectivo),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto en transferencia",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_en_transferencia),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto de desembolso",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_de_egreso),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto en tránsito efectivo",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.dinero_en_transito),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto en tránsito otros",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.dinero_en_transito_otros),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Sus descuentos",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.descuentos),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Cierre total",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_de_cierre_total),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  bold: true,
                },
              ],
              [
                {
                  text: "Efectivo en caja",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_en_caja),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  bold: true,
                },
              ],
              [
                {
                  text: "CIERRE OFICIAL",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: "red",
                  bold: true,
                },
                {
                  text:
                    money() +
                    format(
                      parseFloat(registers.monto_de_cierre_total) -
                        parseFloat(registers.monto_inicial)
                    ),
                  fontSize: styles.fontSize.ticket.md,
                  color: "red",
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  bold: true,
                },
              ],
              [
                {
                  text: "Pagos del mensajero",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.pago_mensajero),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  bold: true,
                },
              ],
              [
                {
                  text: "Pagos cuentas por cobrar",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.pagos_cuentas_por_cobrar),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  bold: true,
                },
              ],
              [
                {
                  text: "Monto faltante",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_faltante),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monto Sobrante",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + format(registers.monto_sobrante),
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                ...(boxConfiguration
                  ? [
                      // Comprueba la condición con un operador ternario
                      {
                        text: "Billetes de 10",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.billete_10,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                ...(boxConfiguration
                  ? [
                      {
                        text: "Billetes de 20",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.billete_20,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                {
                  text: "Billetes de 50",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.billete_50,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Billetes de 100",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.billete_100,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Billetes de 200",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.billete_200,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                ...(boxConfiguration === false
                  ? [
                      {
                        text: "Billetes de 500",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.billete_500,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                ...(boxConfiguration === false
                  ? [
                      {
                        text: "Billetes de 1000",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.billete_1000,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                ...(boxConfiguration === false
                  ? [
                      {
                        text: "Billetes de 2000",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.billete_2000,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                ...(boxConfiguration
                  ? [
                      {
                        text: "Monedas de 50",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.moneda_50,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                {
                  text: "Monedas de 1",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.moneda_1,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                ...(boxConfiguration
                  ? [
                      {
                        text: "Monedas de 2",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.moneda_2,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                {
                  text: "Monedas de 5",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.moneda_5,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                {
                  text: "Monedas de 10",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.moneda_10,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                },
              ],
              [
                ...(boxConfiguration
                  ? [
                      {
                        text: "Monedas de 20",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.moneda_20,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                ...(boxConfiguration === false
                  ? [
                      {
                        text: "Monedas de 25",
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        valign: "middle",
                        fontSize: styles.fontSize.ticket.lg,
                        color: styles.colors.black,
                        bold: true,
                      },
                      {
                        text: registers.moneda_25,
                        fontSize: styles.fontSize.ticket.md,
                        color: styles.colors.black,
                        border: [false, false, false, false],
                        valign: "middle",
                        margin: [0, 0],
                      },
                    ]
                  : [
                      { text: null, border: [0, 0], colSpan: 2, lineHeight: 0 },
                    ]),
              ],
              [
                {
                  text: ":::::::::::::::: Comentario adicional ::::::::::::::::".toUpperCase(),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                  colSpan: 2,
                },
                "",
              ],
              [
                {
                  text: registers.comentario
                    ? registers.comentario
                    : "" +
                      "\n Dinero que se quedó en caja: " +
                      money() +
                      "" +
                      format(registers.dinero_que_queda) +
                      "\n\n Dinero que envió: " +
                      money() +
                      "" +
                      format(registers.dinero_que_deposita) +
                      `${
                        parseFloat(registers.pagos_cuentas_por_cobrar) > 0
                          ? "\n\n Cierre final incluido pagos de cuentas por cobrar " +
                            money() +
                            format(
                              parseFloat(registers.pagos_cuentas_por_cobrar) +
                                parseFloat(registers.monto_de_cierre_total)
                            )
                          : ""
                      }`,
                  fontSize: styles.fontSize.ticket.md,
                  color: styles.colors.black,
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  colSpan: 2,
                },
                "",
              ],
              [
                {
                  text: "Importante: En el cierre oficial no esta incluido el monto de apertura de la caja",
                  fontSize: styles.fontSize.ticket.md,
                  color: "red",
                  border: [false, false, false, false],
                  valign: "middle",
                  margin: [0, 0],
                  colSpan: 2,
                },
                "",
              ],
            ],
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 5, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.black
                : styles.colors.black;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.black
                : styles.colors.black;
            },
          },
        },
      ],
    };
    pdfMake.createPdf(docDefinition).print();
    await closeAlert();
  } catch (error) {
    console.log(error);
    errorActions("No se pudo generar el reporte");
  }
};

const order = async (idOrder) => {
  try {
    const response = await axios.get(`caja/print/voucher/pdf/${idOrder}`);
    return await response.data;
  } catch (error) {
    errorActions(error);
  }
};
