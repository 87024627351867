<template>
  <div id="contentReport">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-start mb-3">
      <!-- componente para mfiltrar por estado de orden de compra -->
      <div class="flex flex-wrap items-end gap-4">
        <label class="block md:mt-0 w-full md:w-auto">
          <span class="block text-gray-600 text-sm font-semibold mb-1"
            >Categoría de filtros</span
          >
          <select
            v-model="dataForm.typeFilter"
            class="bg-white border-2 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500"
            @change="changeTypeFilter"
          >
            <option value="further">productos mas vendidos</option>
            <option value="less">productos menos vendidos</option>
          </select>
        </label>
        <label class="block">
          <span
            class="block mb-1 text-sm font-medium text-gray-800 dark:text-white"
          >
            Top de productos
          </span>
          <input
            type="number"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-500 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.top"
            @keydown.enter.prevent
          />
        </label>
        <label class="block">
          <span
            class="block mb-1 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-500 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block">
          <span
            class="block mb-1 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-500 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
        <button
          class="bg-blue-500 px-3 py-2 text-sm font-semibold text-white rounded-md border-2 border-blue-500 active:scale-95"
          @click="getDataBestSellingAndLessSoldProductReport"
        >
          Consultar
        </button>
        <button
          class="bg-green-500 px-3 py-2 text-sm font-semibold text-white rounded-md border-2 border-green-500 active:scale-95"
          @click="exportToExcel(dataForm.dataReport.products,dataForm.titleChart,dataForm.dateStart,dataForm.dateEnd)"
          v-show="!loading"
        >
          Exportar
        </button>
      </div>
    </div>
    <div v-if="loading"></div>
    <div v-else>
      <ChartComponent
        :datas="dataForm.dataReport.products"
        :titleChart="dataForm.titleChart"
      />
      <!-- seccion de conteido de datos tablas
                1: Para poner una tabla responsiva añadir lo siguiente:
                  1.1: class="resize-generate"
                  1.2: ref="widthNav" 
                  1.3: @resize="handleResize"
                -->
      <div
        class="w-full resize-x resize-generate m-3"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="4" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in dataForm.dataReport.products"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ format(register.cantidad.replace(",", "")) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ money() }}
                  {{ format(register.ganancias.replace(",", "")) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                >
                  {{ money() }}
                  {{ format(register.venta_total.replace(",", "")) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "@/api";
import { errorActions, warningActions } from "@/alerts";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import { formatDateTime } from "@/publicjs/formatDate";
import ChartComponent from "@/components/Reports/Products/BestSellingAndLessSoldProductReport/ChartComponent.vue";
import { idLocalStore } from "@/publicjs/localStorage";
import { observeElement } from "@/observer";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { money, format } from "@/publicjs/money";

import { exportToExcel } from './Excel/ExcelExportAllSales'

export default {
  name: "MainBestSellingAndLessSoldProductReport",
  components: {
    ChartComponent,
    LoadingTables,
    TableHead,
  },
  setup() {
    const loading = ref(true);
    // carga de datos a la tabla
    const header = [
      [
        {
          title: "productos",
        },
        {
          title: "cant. vendida",
        },
        {
          title: "ganancia",
        },
        {
          title: "venta total",
        },
      ],
    ];

    const dataForm = ref({
      url: "reporte-de-productos-mas-vendidos",
      typeFilter: "further",
      dateStart: null,
      dateEnd: null,
      idStore: 0,
      top:5,
      dataReport: {
        month: {},
        products: [],
      },
      titleChart: "REPORTE DE PRODUCTOS CON MÁS MOVIMIENTO",
    });

    const dataNull = ref({
      month: {
        NINGUNA: {
          ganancias: 0,
          venta_total: 0,
        },
      },
      products: {
        NINGUNA: {
          ganancias: 0,
          venta_total: 0,
        },
      },
    });

    const getDataBestSellingAndLessSoldProductReport = async () => {
      loading.value = true;
      dataForm.value.dataReport.products = [];
      if (dataForm.value.typeFilter === "custom") {
        if (
          dataForm.value.dateStart === null ||
          dataForm.value.dateStart === "" ||
          dataForm.value.dateEnd === null ||
          dataForm.value.dateEnd === ""
        ) {
          errorActions("Ingresa el rango de fechas para generar las consultas");
          return;
        }
      }

      const response = await axios
        .get(
          `${dataForm.value.url}/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}&top=${dataForm.value.top}`
        )
        .catch((error) => errorActions(error));

      const data = await response.data;
      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      dataForm.value.dataReport.month =
        data.meses === null ? dataNull.value.month : data.meses;
      dataForm.value.dataReport.products =
        data.productos === null ? dataNull.value.products : data.productos;
      loading.value = false;
    };

    onMounted(async () => {
      observeElement("#contentReport");
      dataForm.value.idStore = await idLocalStore();

      //Pasar el día actual y el ultimo día de la fecha
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = dates.firstDay;
      dataForm.value.dateEnd = dates.lastDay;
    });

    const changeTypeFilter = () => {
      if (dataForm.value.typeFilter === "less") {
        dataForm.value.url = "reporte-de-productos-menos-vendidos";
        dataForm.value.titleChart = "REPORTE DE PRODUCTOS CON MENOS MOVIMIENTO";
      } else {
        dataForm.value.url = "reporte-de-productos-mas-vendidos";
        dataForm.value.titleChart = "REPORTE DE PRODUCTOS CON MÁS MOVIMIENTO";
      }
    };

    return {
      loading,
      formatDateTime,
      dataForm,
      getDataBestSellingAndLessSoldProductReport,
      changeTypeFilter,
      money,
      format,
      header,
      exportToExcel
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
