<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="modalContainer">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] md:max-w-[80vw] lg:max-w-[80vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registrar productos
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div class="flex items-end gap-2">
              <label class="block w-full">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Código de barra <span class="text-red-300">*</span>
                </span>
                <input
                  type="text"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.barCode"
                  placeholder="Descripción..."
                  @keydown.enter.prevent
                  maxlength="30"
                />
              </label>
              <button
                class="py-2 bg-blue-500 text-white px-2 rounded-md"
                @click="barCodeGenerate"
              >
                Generar
              </button>
            </div>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombre del producto <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.productName"
                placeholder="Nombre del producto..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Descripción
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.description"
                placeholder="Descripción..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Stock <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.stock"
                placeholder="Stock..."
                @keydown.enter.prevent
                step="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Stock mínimo <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.minimumStock"
                placeholder="Stock mínimo..."
                @keydown.enter.prevent
                step="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Descuento (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.discount"
                placeholder="Descuento..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio de compra <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none border-orange-300 rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.purchasePrice"
                placeholder="Precio de compra..."
                @keydown.enter.prevent
                step="0.01"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Utilidad (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg border-sky-400 text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.utility"
                placeholder="Utilidad..."
                @keydown.enter.prevent
                @keydown.enter="calcUtility"
                @input="calcUtility"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio de venta <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 text-green-600 border-green-500 outline-none rounded-lg w-full border-2 focus:border-blue-500 font-bold"
                v-model="dataForm.salePrice"
                placeholder="Precio de venta..."
                @keydown.enter.prevent
                @input="calcUtilityFromSalePrice"
                step="0.01"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Ubicación
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.location"
                placeholder="Ubicación..."
                @keydown.enter.prevent
                maxlength="40"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Libre de impuesto?
              </span>
              <select
                class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none cursor-pointer"
                v-model="dataForm.taxFree"
                @change="changeTaxtFree"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Facturado?
              </span>
              <select
                class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none cursor-pointer"
                v-model="dataForm.invoiced"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de vencimiento
              </span>
              <input
                type="date"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.expiration"
                placeholder="Fecha de vencimiento..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores
                @send-data="getSelectStore"
                :selectOption="dataForm.idStore"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de productos
              </span>
              <TypeOfProducts @send-data="getSelectTypeOfProducts" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio de oferta
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.precio_descuento"
                placeholder="Precio de oferta"
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Vencimiento de oferta
              </span>
              <input
                type="datetime-local"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.vencimiento_descuento"
                @keydown.enter.prevent
              />
            </label>

            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Vencimiento de garantía
              </span>
              <div class="flex items-center">
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-tl-md rounded-bl-md rounded- text-gray-600 w-full border-l-2 border-y-2 focus:border-blue-500"
                  v-model="dataForm.vencimiento_garantia"
                  @keydown.enter.prevent
                  placeholder="Tiempo de garantía"
                />
                <select
                  v-model="dataForm.tipo_vencimiento"
                  name="tipo_vencimiento"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-br-md rounded-tr-md text-gray-600 w-full border-y-2 border-r-2 focus:border-blue-500"
                >
                  <option value="year">Años</option>
                  <option value="month">Meses</option>
                  <option value="days">Días</option>
                </select>
              </div>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.igv"
                :placeholder="config.iva + '...'"
                @keydown.enter.prevent
              />
            </label>
            <!-- <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.igv"
                :placeholder="config.iva + '...'"
                @keydown.enter.prevent
              />
            </label> -->
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Venta con stock?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.saleWithStock"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.saleWithStock === true ? "SI" : "NO" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado actual
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Especificar tipo
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.type"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.type === true ? "Producto" : "Servicio" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Está publicado en marketplace?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.published"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.published === true ? "SI" : "NO" }}
                </span>
              </div>
            </label>
            <div
              class="md:col-span-2 lg:col-span-3 border-2 border-blue-100 px-3 py-3 rounded-lg"
            >
              <h2 class="text-md mb-2 font-semibold text-blue-500">
                Configurar unidad de medida del producto
              </h2>
              <!-- formulario para agregar una unidad de medida del producto -->
              <div class="grid md:grid-cols-2 gap-6">
                <div>
                  <div
                    class="flex justify-between flex-wrap md:flex-nowrap gap-3"
                  >
                    <label class="block w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        U.M
                      </span>
                      <UnitMeasure @send-data="getSelectUnitMeasure" />
                    </label>
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Cantidad contenido<span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataForm.quantity"
                        placeholder="Cantidad en unidades..."
                        @keydown.enter.prevent
                        @keydown.enter="addUnitMeasure"
                      />
                    </label>
                    <button
                      class="bg-blue-50 mb-2 text-blue-500 rounded-lg px-3 py-2 hover:bg-blue-200 active:scale-95 w-full h-10 mt-7"
                      @click="addUnitMeasure"
                      :disabled="dataForm.unitMeasures.length > 0"
                    >
                      Agregar
                    </button>
                  </div>
                  <!-- precio 1 -->
                  <div class="flex items-end gap-4 border-b">
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Precio 1 <span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataFormUnitMeasure.price"
                        placeholder="Precio..."
                        @keydown.enter.prevent
                        @input="calcUtilityFromSalePriceOne"
                      />
                    </label>
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Utilidad <span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataFormUnitMeasure.utility"
                        placeholder="Precio..."
                        @keydown.enter.prevent
                        @input="calcUtilityPriceOne"
                      />
                    </label>
                  </div>
                  <!-- precio 2 -->
                  <div class="flex items-end gap-4 border-b mt-2">
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Precio 2 <span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataFormUnitMeasure.price_two"
                        placeholder="Precio..."
                        @keydown.enter.prevent
                        @input="calcUtilityFromSalePriceTwo"
                      />
                    </label>
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Utilidad <span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataFormUnitMeasure.utility_price_two"
                        placeholder="Precio..."
                        @keydown.enter.prevent
                        @input="calcUtilityPriceTow"
                      />
                    </label>
                  </div>
                  <!-- precio 3 -->
                  <div class="flex items-end gap-4 border-b mt-2">
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Precio 3 <span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataFormUnitMeasure.price_three"
                        placeholder="Precio..."
                        @keydown.enter.prevent
                        @input="calcUtilityFromSalePriceThree"
                      />
                    </label>
                    <label class="block mb-2 w-full">
                      <span
                        class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                      >
                        Utilidad <span class="text-red-300">*</span>
                      </span>
                      <input
                        type="number"
                        class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                        v-model="dataFormUnitMeasure.utility_price_three"
                        placeholder="Precio..."
                        @keydown.enter.prevent
                        @input="calcUtilityPriceThree"
                      />
                    </label>
                  </div>
                </div>
                <div class="flex gap-4 flex-wrap md:mt-7">
                  <div
                    class="px-3 py-2 rounded-md bg-blue-50 text-blue-500 w-full relative overflow-hidden"
                    v-for="(unitMeasure, i) in dataForm.unitMeasures"
                    :key="i"
                  >
                    PRECIOS DE LA UNIDAD DE MEDIDA
                    <strong>{{ unitMeasure.name }} </strong>
                    <button
                      class="p-2 bg-red-500 ml-2 cursor-pointer py-1 text-white text-[11px] font-bold absolute top-0 right-0"
                      @click="removeUnitMeasure(unitMeasure.idUnitMeasure)"
                    >
                      X
                    </button>
                    <div class="mt-2">
                      <div>
                        Precio 1
                        <strong> ${{ unitMeasure.price }} </strong>
                      </div>
                      <div>
                        Precio 2
                        <strong> ${{ unitMeasure.price_two }} </strong>
                      </div>
                      <div>
                        Precio 3
                        <strong> ${{ unitMeasure.price_three }} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full" v-if="stateInputsActive">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Poster
              </span>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar imagen</span
                    >
                    PNG o JPG
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  accept=".png,.jpg"
                  ref="poster"
                  @change="selectFilePoster($event)"
                />
              </label>
            </div>
            <div class="w-full" v-if="stateInputsActive">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Ficha técnica
              </span>
              <label
                for="datSheet"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar archivo</span
                    >
                    .PDF
                  </p>
                </div>
                <input
                  id="datSheet"
                  type="file"
                  class="hidden"
                  accept=".pdf"
                  ref="datSheet"
                  @change="selectFileDatSheet($event)"
                />
              </label>
            </div>
            <div class="w-full" v-if="stateInputsActive">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Registro sanitario
              </span>
              <label
                for="healthRegister"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar archivo</span
                    >
                    .PDF
                  </p>
                </div>
                <input
                  id="healthRegister"
                  type="file"
                  class="hidden"
                  accept=".pdf"
                  ref="healthRegister"
                  @change="selectFileHealthRegisters($event)"
                />
              </label>
            </div>
          </div>
          <div
            class="flex justify-between mt-[3.5rem!important] lg:mt-[2rem!important]"
          >
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
            <button
              class="bg-gray-100 text-blue-500 text-sm px-3 py-2 rounded-lg hover:bg-blue-100"
              @click="inputsActive()"
            >
              Mostrar más opciones
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../../api";

//componentes publicos

import Stores from "@/components/PublicComponents/Stores.vue";
import UnitMeasure from "@/components/PublicComponents/UnitMeasure.vue";
import TypeOfProducts from "@/components/PublicComponents/TypeOfProducts.vue";

//convertirdor de porcentajes
import { percentage } from "@/publicjs/convertToPercentage";
import config from "/public/apis/config.json";

//Importacion para registrar movimiento de producto
import { insertproductsAccordingToModuleEntered } from "@/repository/Store/ProductsAccordingToModuleEntered/productsAccordingToModuleEntered";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";

export default {
  name: "RegisterProducts",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Stores,
    UnitMeasure,
    TypeOfProducts,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información
    const stateInputsActive = ref(false);

    //variables para guardar los archivos
    const datSheet = ref(null);
    const healthRegister = ref(null);
    const poster = ref(null);

    const dataForm = ref({
      idStore: null,
      idTypeOfProduct: null,
      barCode: "",
      productName: "",
      description: "",
      discount: 0,
      taxFree: "NO",
      invoiced: "SI",
      location: "",
      utility: 0,
      expiration: "",
      igv: 18,
      state: true,
      idProduct: 0,
      stock: 0,
      minimumStock: 5,
      purchasePrice: 0,
      salePrice: 0,
      saleWithStock: true,
      type: true,
      published: true,
      idUnitMeasure: 0,
      quantity: 1,
      price: 0,
      textOption: "",
      unitMeasures: [],
      precio_descuento: 0,
      vencimiento_descuento: null,
      vencimiento_garantia: 0,
      tipo_vencimiento: "year",
    });

    const dataFormUnitMeasure = ref({
      price: 0,
      utility: 0,
      price_two: 0,
      utility_price_two: 0,
      price_three: 0,
      utility_price_three: 0,
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectStore = (data) => {
      dataForm.value.idStore = data;
    };
    //recepcion de datos desde el componente publico, todas los tipo de productos
    const getSelectTypeOfProducts = (data) => {
      dataForm.value.idTypeOfProduct = data;
    };
    //recepcion de datos desde el componente publico, todas las unidades de medida
    const getSelectUnitMeasure = (data) => {
      dataForm.value.idUnitMeasure = data;
    };

    //funcion para agregar unidades de medida del producto
    const addUnitMeasure = () => {
      const unitSelect = document.getElementById("unitMeasure");
      if (unitSelect.value === null || unitSelect.value === "null") {
        errorActions("Selecciona la unidad de medida del producto");

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.quantity === null || dataForm.value.quantity === 0) {
        errorActions(
          "Ingresa la cantidad que contiene en unidades el producto"
        );

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (
        dataFormUnitMeasure.value.price === null ||
        dataFormUnitMeasure.value.price === 0
      ) {
        errorActions(
          "Ingresa el precio del producto por la unidad de medida que estas intentando agregar"
        );

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      // verifica si existe un objeto con el valor que le estamos enviando
      const foundIndex = dataForm.value.unitMeasures.findIndex(
        (module) => module.idUnitMeasure === dataForm.value.idUnitMeasure
      );
      // si existe el registro con el mismo id lo eliminamos
      if (foundIndex > -1) {
        dataForm.value.unitMeasures.splice(foundIndex, 1);
      } else {
        //obtenemos el texto del option
        const selectElement = document.getElementById("unitMeasure");
        dataForm.value.textOption =
          selectElement.options[selectElement.selectedIndex].text;

        // si no existe simplemente agregamos el objeto al arreglo
        const data = {
          idUnitMeasure: dataForm.value.idUnitMeasure,
          name: dataForm.value.textOption,
          quantity: dataForm.value.quantity,
          price: dataFormUnitMeasure.value.price,
          utility: dataForm.value.utility,
          price_two: dataFormUnitMeasure.value.price_two,
          utility_price_two: dataFormUnitMeasure.value.utility_price_two,
          price_three: dataFormUnitMeasure.value.price_three,
          utility_price_three: dataFormUnitMeasure.value.utility_price_three,
          state: 1,
        };
        dataForm.value.unitMeasures.push(data);
        console.log(dataForm.value.unitMeasures);
      }
    };
    //remover unidades de medidas añadidas
    const removeUnitMeasure = (id) => {
      // verifica si existe un objeto con el valor que le estamos enviando
      const foundIndex = dataForm.value.unitMeasures.findIndex(
        (module) => module.idUnitMeasure === id
      );
      // si existe el registro con el mismo id lo eliminamos
      if (foundIndex > -1) {
        dataForm.value.unitMeasures.splice(foundIndex, 1);
      }
    };

    //funciona para activar input opcionales
    const inputsActive = () => {
      stateInputsActive.value = !stateInputsActive.value;
    };

    // Selecciona el foto de prefil
    const selectFilePoster = async (e) => {
      poster.value = await e.target.files[0];
    };
    // Selecciona registro sanitario
    const selectFileHealthRegisters = async (e) => {
      healthRegister.value = await e.target.files[0];
    };
    // Selecciona ficha tenica
    const selectFileDatSheet = async (e) => {
      datSheet.value = await e.target.files[0];
    };

    //funcion para calcular la utilidad de l producto
    const calcUtility = () => {
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          "Ingresa el precio de compra del <strong>producto</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      let salePrice = 0;
      const purchasePrice = parseFloat(dataForm.value.purchasePrice);
      const totalSales = purchasePrice * percentage(dataForm.value.utility);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10; // si no funciona dividir entre 100 y mult. * 100
      dataForm.value.salePrice = salePrice.toFixed(2);
      dataForm.value.price = salePrice.toFixed(2);

      dataFormUnitMeasure.value.price = dataForm.value.price;
      dataFormUnitMeasure.value.utility = dataForm.value.utility;
    };

    //funcion para calcular el precio 1 del producto
    const calcUtilityPriceOne = () => {
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          "Ingresa el precio de compra del <strong>producto</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      let salePrice = 0;
      const purchasePrice = parseFloat(dataForm.value.purchasePrice);
      const totalSales =
        purchasePrice * percentage(dataFormUnitMeasure.value.utility);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10;
      dataFormUnitMeasure.value.price = salePrice.toFixed(2);

      dataForm.value.price = dataFormUnitMeasure.value.price;
      dataForm.value.salePrice = dataFormUnitMeasure.value.price;
      dataForm.value.utility = dataFormUnitMeasure.value.utility;
    };
    //funcion para calcular el precio 2 del producto
    const calcUtilityPriceTow = () => {
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          "Ingresa el precio de compra del <strong>producto</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      let salePrice = 0;
      const purchasePrice = parseFloat(dataForm.value.purchasePrice);
      const totalSales =
        purchasePrice * percentage(dataFormUnitMeasure.value.utility_price_two);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10;
      dataFormUnitMeasure.value.price_two = salePrice.toFixed(2);
    };
    //funcion para calcular el precio 3 del producto
    const calcUtilityPriceThree = () => {
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          "Ingresa el precio de compra del <strong>producto</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      let salePrice = 0;
      const purchasePrice = parseFloat(dataForm.value.purchasePrice);
      const totalSales =
        purchasePrice *
        percentage(dataFormUnitMeasure.value.utility_price_three);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10;
      dataFormUnitMeasure.value.price_three = salePrice.toFixed(2);
    };

    const calcUtilityFromSalePriceOne = async () => {
      let shoppinPrice = dataForm.value.purchasePrice;
      let salePrice = dataFormUnitMeasure.value.price;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      // Redondear el porcentaje a un número entero
      dataFormUnitMeasure.value.utility = Math.round(porcentajeGanancia);
      dataForm.value.utility = Math.round(porcentajeGanancia);
      dataForm.value.salePrice = dataFormUnitMeasure.value.price;
    };
    const calcUtilityFromSalePriceTwo = async () => {
      let shoppinPrice = dataForm.value.purchasePrice;
      let salePrice = dataFormUnitMeasure.value.price_two;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;
      dataFormUnitMeasure.value.utility_price_two =
        Math.round(porcentajeGanancia);
    };
    const calcUtilityFromSalePriceThree = async () => {
      let shoppinPrice = dataForm.value.purchasePrice;
      let salePrice = dataFormUnitMeasure.value.price_three;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;
      dataFormUnitMeasure.value.utility_price_three =
        Math.round(porcentajeGanancia);
    };

    const calcUtilityFromSalePrice = async () => {
      // Precio de compra y venta
      let shoppinPrice = dataForm.value.purchasePrice;
      let salePrice = dataForm.value.salePrice;
      let porcentajeGanancia = 0;
      // Calcular el porcentaje de ganancia
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      // Redondear el porcentaje a un número entero
      dataForm.value.utility = Math.round(porcentajeGanancia);
      dataForm.value.price = dataForm.value.salePrice;

      dataFormUnitMeasure.value.price = dataForm.value.price;
      dataFormUnitMeasure.value.utility = dataForm.value.utility;
    };

    /*************************************************
     * FUNCIÓN PARA GUARDAR EL PRODUCTO
     *************************************************/
    const insert = async () => {
      if (dataForm.value.productName.trim().length <= 3) {
        errorActions("Ingresa el nombre del <strong>producto</strong>");
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.barCode.trim().length <= 3) {
        errorActions(
          `Ingresa un codigo de barra del producto <strong>${dataForm.value.productName}</strong>`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if ( isNaN(dataForm.value.stock) || dataForm.value.stock < 0) {
        errorActions(
          `El STOCK del producto debe de ser mayor o igual a (0)`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if ( isNaN(dataForm.value.minimumStock) || dataForm.value.minimumStock <= 0) {
        errorActions(
          `Ingresa un valor en el stock minimo del producto <strong>${dataForm.value.productName}</strong>, este te servira para que el sistema te alerte cuando el stock del producto se este agotando`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          `Ingresa el precio de compra del producto <strong>${dataForm.value.productName}</strong>, este te servira parasacar tu margen de utilidad`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.salePrice <= 0) {
        errorActions(
          `El precio de venta del producto <strong>${dataForm.value.productName}</strong>, no debe de ir con el valor de 0, ingresa el precio de compra, luego ingresa la utilidad y finalmente preciona enter en al campo utilidad`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.utility <= dataForm.value.discount) {
        errorActions(
          `La utilidad del producto <strong>${dataForm.value.productName}</strong>, no debe de ser menor al descuento del producto`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.idProduct === null) {
        errorActions(
          `Selecciona una sucursal para el producto <strong>${dataForm.value.productName}</strong>.`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.idStore === null) {
        errorActions(
          `Selecciona la sucursal donde deseas agregar el producto  <strong>${dataForm.value.productName}</strong>.`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.idTypeOfProduct === null) {
        errorActions(
          `Selecciona un tipo de producto para el producto <strong>${dataForm.value.productName}</strong>.`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.igv <= 0 && dataForm.value.taxFree === "NO") {
        errorActions(
          `El iva del producto <strong>${dataForm.value.productName}</strong>, no debe de ir con el valor de 0, ingresa el impuesto agregado del producto`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      if (dataForm.value.unitMeasures.length <= 0) {
        errorActions(
          `Configura las unidades de medida que tendra el producto <strong>${dataForm.value.productName}</strong>.`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      /********************************************
       * formaData
       ********************************************/
      const formValue = new FormData();
      formValue.append("idStore", dataForm.value.idStore);
      formValue.append("idTypeOfProduct", dataForm.value.idTypeOfProduct);
      formValue.append("barCode", dataForm.value.barCode);
      formValue.append("productName", dataForm.value.productName);
      formValue.append("description", dataForm.value.description);
      formValue.append("discount", dataForm.value.discount);
      formValue.append("taxFree", dataForm.value.taxFree);
      formValue.append("invoiced", dataForm.value.invoiced);
      formValue.append("location", dataForm.value.location);
      formValue.append("utility", dataForm.value.utility);
      formValue.append("expiration", dataForm.value.expiration);
      formValue.append("igv", dataForm.value.igv);
      formValue.append("healthRegister", healthRegister.value);
      formValue.append("datSheet", datSheet.value);
      formValue.append("poster", poster.value);
      formValue.append("state", dataForm.value.state ? 1 : 0);
      formValue.append("type", dataForm.value.type ? 1 : 0);
      formValue.append("published", dataForm.value.published ? 1 : 0);
      formValue.append(
        "unitMeasures",
        JSON.stringify(dataForm.value.unitMeasures)
      );
      formValue.append(
        "store_product",
        JSON.stringify({
          stock: dataForm.value.stock,
          minimumStock: dataForm.value.minimumStock,
          purchasePrice: dataForm.value.purchasePrice,
          salePrice: dataForm.value.salePrice,
          precio_descuento: dataForm.value.precio_descuento,
          vencimiento_descuento: dataForm.value.vencimiento_descuento,
          vencimiento_garantia: dataForm.value.vencimiento_garantia,
          tipo_vencimiento: dataForm.value.tipo_vencimiento,
          saleWithStock: dataForm.value.saleWithStock ? 1 : 0,
        })
      );

      spinner.value = true;
      const response = await axios
        .post("productos", formValue, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        insertproductsAccordingToModuleEntered({
          idStore: dataForm.value.idStore,
          idUser: await idLocalUser(),
          idProduct: data.id,
          quantity: dataForm.value.stock,
          module: "ALMACEN",
        });

        confirmationOfRecord(
          `El producto  <strong>${dataForm.value.productName}</strong>`
        );
        props.getdata();
        dataForm.value.barCode = "";
        dataForm.value.productName = "";
        dataForm.value.description = "";
        dataForm.value.discount = 0;
        dataForm.value.taxFree = "SI";
        dataForm.value.invoiced = "SI";
        dataForm.value.location = "";
        dataForm.value.utility = 0;
        dataForm.value.expiration = null;
        dataForm.value.igv = 18;
        dataForm.value.idProduct = 0;
        dataForm.value.stock = 0;
        dataForm.value.minimumStock = 5;
        dataForm.value.purchasePrice = 0;
        dataForm.value.salePrice = 0;
        dataForm.value.price = 0;
        dataForm.value.saleWithStock = true;
        dataForm.value.unitMeasures = [];
        healthRegister.value ? (healthRegister.value.type = "text") : null;
        healthRegister.value ? (healthRegister.value.type = "file") : null;
        datSheet.value ? (datSheet.value.type = "text") : null;
        datSheet.value ? (datSheet.value.type = "file") : null;
        poster.value ? (poster.value.type = "text") : null;
        poster.value ? (poster.value.type = "file") : null;
        dataForm.value.state = true;

        dataFormUnitMeasure.value.price = 0;
        dataFormUnitMeasure.value.price_two = 0;
        dataFormUnitMeasure.value.price_three = 0;
        dataFormUnitMeasure.value.utility = 0;
        dataFormUnitMeasure.value.utility_price_three = 0;
        dataFormUnitMeasure.value.utility_price_two = 0;
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
        spinner.value = false;
      } else if (data.msg === "exist") {
        errorActions(
          `Ya existe un  <strong>producto</strong> con el mismo codigo de barra a la que estas intentando registrar`
        );
        spinner.value = false;
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el producto <strong>${dataForm.value.description}</strong>`
        );
        spinner.value = false;
      }

      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    const changeTaxtFree = () => {
      if (dataForm.value.taxFree === "NO") {
        dataForm.value.igv = 18;
      } else {
        dataForm.value.igv = 0;
      }
    };

    onBeforeMount(async () => {
      dataForm.value.idStore = await idLocalStore();
    });
    //observer
    onMounted(() => {
      observer.observe(
        document.getElementById("modalContainer"),
        { box: "content-box" },
        10
      );
    });

    const barCodeGenerate = () => {
      let number = Math.floor(Math.random() * 10000000);
      dataForm.value.barCode = String(number).padStart(6, "0");
    };

    return {
      isActive,
      dataForm,
      insert,
      spinner,
      inputsActive,
      stateInputsActive,
      getSelectStore,
      getSelectTypeOfProducts,
      getSelectUnitMeasure,
      addUnitMeasure,
      removeUnitMeasure,
      selectFilePoster,
      selectFileHealthRegisters,
      selectFileDatSheet,
      calcUtility,
      config,
      calcUtilityFromSalePrice,
      changeTaxtFree,
      barCodeGenerate,
      dataFormUnitMeasure,
      calcUtilityPriceTow,
      calcUtilityPriceThree,
      calcUtilityPriceOne,
      calcUtilityFromSalePriceOne,
      calcUtilityFromSalePriceTwo,
      calcUtilityFromSalePriceThree,
    };
  },
};
</script>
